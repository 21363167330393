import React, {lazy} from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

const Layout = lazy(()=> import("./components/Layout"))
const CustomerInfo = lazy(()=> import("./pages/CustomerInfo"))
const NotFound = lazy(()=> import("./pages/NotFound"))

function App() {

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    index: true,
                    element: <CustomerInfo/>,
                },
                {
                    path: "/404",
                    element: <NotFound/>,
                }
            ]
        },

    ]);


  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
